import styled, { css } from "styled-components"

export const LandingHead = styled.div`
  display: flex;
  justify-content: center;
  /* background-color: red; */
  width: 100%;
  height: fit-content;
  padding: 2rem;
  padding-left: 5rem;
  padding-right: 5rem;
  align-items: center;
  overflow: hidden;
  @media (max-width: 800px) {
    padding-left: 2rem;
    padding-right: 2rem;
    flex-direction: column;
    height: fit-content;
  }
  @media (max-width: 880px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  div.right {
    height: 100%;
    width: 50%;
    /* background-color: pink; */
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    padding: 3rem;
    /* margin-top: 120px; */
    @media (max-width: 800px) {
      height: fit-content;
      width: 100%;
      padding: 2rem;
      align-items: center;
    }
  }

  h1.title {
    font-family: "pbold";
    font-size: 2.5rem;
    color: #2c3d63;
    text-align: left;
    margin-top: 5px;
    margin-bottom: 5px;
    @media (max-width: 800px) {
      text-align: center;
    }
  }

  p.detail {
    font-family: "pmedium";
    font-size: 16px;
    text-align: left;
    max-width: 26rem;
    color: #2c3d63;
    @media (max-width: 800px) {
      text-align: center;
      text-justify: distribute;
    }
  }

  div.left {
    height: 100%;
    width: 50%;
    padding-top: 0px;
    display: flex;
    justify-content: center;
    align-items: center !important;
    flex-direction: column;
    align-content: center; /* margin-top: 120px; */

    @media (max-width: 800px) {
      display: none;
    }
  }

  div.Buttons {
    display: flex;
    justify-content: left;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    /* background-color: pink; */
    @media (max-width: 800px) {
      align-items: center;
      justify-content: center;
    }
  }

  .buttonleft {
    margin-left: 0px;
    margin-right: 0.8rem;
    @media (max-width: 800px) {
      margin: 0.8rem;
      margin-top: 0.4rem;
    }
  }

  .illustration {
    width: 80%;
  }

  .onda {
    position: absolute;
    right: 0px;
    top: 0px;
    width: 40rem;
    z-index: -20;
  }
  .bolaverde {
    position: absolute;
    left: -2.9rem;
    top: 13rem;
    width: 50%;
    z-index: -20;
    @media (max-width: 890px) {
      display: none;
    }
  }
`

export const SecondLanding = styled.div`
  display: flex;
  justify-content: flex-start;
  /* background-color: red; */
  width: 100%;
  height: fit-content;
  padding: 2rem;
  padding-left: 5rem;
  padding-right: 5rem;
  overflow: hidden;
  flex-direction: column;
  align-items: center;
  position: relative;
  @media (max-width: 800px) {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  @media (max-width: 880px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  div.targetContainer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 1.5rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    /* background-color: pink; */
    width: 100%;
    height: 100%;
    @media (max-width: 800px) {
      margin: 0.8rem;
    }
  }
  .bolanaranja {
    position: absolute;
    right: -20px;
    width: 50%;
    z-index: -20;
    top: 10rem;
    @media (max-width: 890px) {
      display: none;
    }
  }
`

export const Targeta = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 20rem;
  height: 23rem;
  background-color: white;
  border-radius: 8px;
  margin: 2rem;
  padding: 0.7rem;
  -webkit-box-shadow: 0px 10px 28px -11px rgba(173, 220, 202, 1);
  -moz-box-shadow: 0px 10px 28px -11px rgba(173, 220, 202, 1);
  box-shadow: 0px 10px 28px -11px rgba(173, 220, 202, 1);

  @media (max-width: 800px) {
    margin: 0.8rem;
  }

  h4 {
    font-family: "psemibold";
    color: #2c3d63;
    font-size: 18px;
    display: flex;
    justify-content: center;
    text-align: center;
  }
  svg {
    width: 50%;
    margin-left: 1rem;
  }

  p {
    font-family: "plight";
    text-align: center;
    color: #2c3d63;
  }
`

export const GaleryContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  background-color: white;
  width: 100%;
  height: fit-content;
  padding: 2rem;
  padding-left: 5rem;
  padding-right: 5rem;
  overflow: hidden;
  align-items: center;
  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;
    padding-left: 2rem;
    padding-right: 2rem;
    flex-direction: column;
    height: fit-content;
  }

  div.bigImage {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    /* padding: 2rem; */
    /* background-color: red; */
    width: 50%;
    height: 50%;
    position: relative;
    border-radius: 15px;
    overflow: hidden;
    @media (max-width: 800px) {
      width: 100%;
    }
  }

  div.left {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    padding: 2rem;
    padding-top: 0.7rem;
    /* background-color: pink; */
    width: 60%;
    @media (max-width: 800px) {
      width: 100%;
      align-items: center;
      justify-content: center;
    }
    h2 {
      font-family: "pbold", sans-serif;
      font-size: 2.1rem;
      color: #2c3d63;
      text-align: center;
      margin-bottom: 0.8rem;
      @media (max-width: 800px) {
        text-align: center;
      }
    }

    h3 {
      font-family: "plight";
      font-size: 12px;
      text-align: left;
      max-width: 90%;
      color: #2c3d63;
      text-align: left;
      width: 100%;
      @media (max-width: 800px) {
        text-align: center;
        margin: auto;
        margin-bottom: 1.2rem;
      }
    }
  }
`

export const ThirdLanding = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  /* background-color: pink; */
  width: 100%;
  height: fit-content;
  padding: 2rem;
  padding-left: 5rem;
  padding-right: 5rem;
  align-items: center;
  overflow: hidden;
  @media (max-width: 800px) {
    padding-left: 2rem;
    padding-right: 2rem;
    flex-direction: column;
    height: fit-content;
  }
  @media (max-width: 880px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  div.video {
    display: flex;
    justify-content: center;
    width: fit-content;

    /* height: 13rem; */
    /* background-color: yellow; */
    margin: 3rem;
    border-radius: 15px;
    overflow: hidden;
    @media (max-width: 800px) {
      width: 100%;
    }
  }
  .bolanaranja {
    position: absolute;
    left: -20px;
    width: 40%;
    z-index: -20;
    /* top: 10rem; */
    @media (max-width: 890px) {
      display: none;
    }
  }
  div.gatsby-image-wrapper {
    height: 50%;
    width: 50%;
    /* margin-left: 5rem; */
    @media (max-width: 800px) {
      height: 100%;
      width: 100%;
    }
  }
`
