import React, { useState } from "react"
import ImageGalery from "../components/ImageGalery"
import { Link, useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
// import Image from "gatsby"
//Styled Components
import { Title } from "../styles/Common"
import { Button } from "../styles/Common"
import Portal from "../HOC/Portal"
import FullGalery from "../components/FullGalery"
import {
  LandingHead,
  SecondLanding,
  Targeta,
  GaleryContainer,
  ThirdLanding,
} from "../styles/LandingStyle"
//SVG'S
import Illustration from "../assets/icons/illustration.svg"
import Onda from "../assets/icons/ondasup.svg"
import BolaVerde from "../assets/icons/bolaverde.svg"
import Profesores from "../assets/icons/profesores.svg"
import Cambridge from "../assets/icons/cambridge.svg"
import Niveles from "../assets/icons/Niveles.svg"
import BolaNaranja from "../assets/icons/bolanaranja.svg"

const Landing = () => {
  const [data, setData] = useState(
    useStaticQuery(graphql`
      {
        allFile(filter: { sourceInstanceName: { eq: "images" } }) {
          edges {
            node {
              childImageSharp {
                fluid(maxWidth: 400, quality: 50) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `)
  )
  const [showGalery, setShowGalery] = useState(false)
  return (
    <>
      {showGalery && (
        <Portal id="FullGalery">
          <FullGalery setShowGalery={setShowGalery} />
        </Portal>
      )}
      <LandingHead>
        <div className="right">
          <h1 className="title">The easy way to learn</h1>
          <p className="detail">
            Tu academia de inglés en Villena con profesorado nativo. Aprende
            inglés de manera secilla e intuitiva con nuestros métodos de
            enseñanza personalizados, ofreciendo un aprendizaje del inglés en
            inglés.
          </p>
          <div className="Buttons">
            <Button primary className="buttonleft">
              <Link to="/contacto">Contacto</Link>
            </Button>
            <Button secondary className="buttonleft">
              <Link to="/cursos">Cursos y precios</Link>
            </Button>
          </div>
        </div>
        <div className="left">
          <Illustration className="illustration" />
        </div>
        <Onda className="onda"></Onda>
        <BolaVerde className="bolaverde" />
      </LandingHead>
      <SecondLanding>
        <Title>
          <h2>¿Por qué estudiar en London School of English?</h2>
          <h3 style={{ fontSize: "16px" }}>
            Nuestros años de experiencia y cualificación nos avalan como la
            mejor opción para que tu nivel de inglés mejore tanto en el ámbito
            académico como profesional.
          </h3>
        </Title>
        <div className="targetContainer">
          <Targeta>
            <h4>Profesores nativos</h4>
            <Profesores></Profesores>
            <p>Nuestros profesores son profesionales titulados y nativos</p>
          </Targeta>
          <Targeta>
            <h4>Preparación de exámenes</h4>
            <Cambridge />
            <p>Te preparamos para tu exámen oficial de Cambridge</p>
          </Targeta>
          <Targeta>
            <h4>Cualquier nivel o edad</h4>
            <Niveles />
            <p>
              Impartimos clases adaptadas a tu nivel, independientemente de tu
              edad
            </p>
          </Targeta>
        </div>
        <BolaNaranja className="bolanaranja" />
      </SecondLanding>
      <GaleryContainer>
        <div className="left">
          <h2 style={{ marginTop: "0px" }}>Nuestra academia</h2>
        </div>
        <div className="bigImage">
          <ImageGalery initialImage={0} />
        </div>
      </GaleryContainer>
      <ThirdLanding>
        <Title>
          <h2>Método directo como modelo de enseñanza</h2>
          <h3 style={{ fontSize: "16px" }}>
            Ponemos en práctica el método de enseñanza directo. Enseñar en
            inglés a partir del primer contacto con el alumn@. Una dinámica de
            trabajo práctica, enfatizando siempre trabajos orales, pronunciación
            RP (fonético) e incluyendo la gramática desde un proceso lógico.
          </h3>
        </Title>
        <div className="video">
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/PYtaq9b_J8g"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
        <Image
          fluid={
            data.allFile.edges[data.allFile.edges.length - 1].node
              .childImageSharp.fluid
          }
        ></Image>

        <BolaVerde className="bolanaranja" />
      </ThirdLanding>
    </>
  )
}

export default Landing
