import React from "react"
import Layout from "../components/Layout"
import LandingPage from "../components/Landing"
import SEO from "../components/SEO"

const IndexPage = () => {
  return (
    <Layout>
      <LandingPage></LandingPage>
    </Layout>
  )
}

export default IndexPage
