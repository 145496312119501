import React, { useEffect, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import { Galery } from "../styles/ImageGaleryStyle"
import RightArrow from "../assets/icons/rightarrow.svg"
import LefttArrow from "../assets/icons/leftarrow.svg"

const ImageGalery = ({ alto, ancho, initialImage }) => {
  const [galleryImages, setGalleryImages] = useState()
  const [currentImage, setCurrentImage] = useState(initialImage)
  const [data, setData] = useState(
    useStaticQuery(graphql`
      {
        allFile(filter: { sourceInstanceName: { eq: "images" } }) {
          edges {
            node {
              childImageSharp {
                fluid(maxWidth: 400, quality: 50) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `)
  )

  return (
    <>
      <Galery alto={alto} ancho={ancho}>
        <div
          style={{ color: "black" }}
          onClick={() =>
            setCurrentImage(prev => {
              return prev !== 0 ? prev - 1 : 0
            })
          }
          className="leftArrow"
        >
          <LefttArrow />
        </div>
        <Image
          fluid={data.allFile.edges[currentImage].node.childImageSharp.fluid}
        ></Image>
        <div
          onClick={() =>
            setCurrentImage(prev => {
              if (currentImage === data.allFile.edges.length - 2) {
                return 0
              } else {
                return prev + 1
              }
            })
          }
          className="rightArrow"
        >
          <RightArrow />
        </div>
      </Galery>
    </>
  )
}

export default ImageGalery
