import styled from "styled-components"

export const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: center;
  align-items: center;
  background-color: red;
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 300;
`
