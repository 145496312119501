import React from "react"
import { Container } from "../styles/FullGaleryStyle"

const FullGalery = ({ setShowGalery }) => {
  return (
    <>
      <Container
        onClick={() => {
          setShowGalery(false)
        }}
      >
        hi
      </Container>
    </>
  )
}

export default FullGalery
