import styled, { css } from "styled-components"

export const Galery = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* background-color: black; */
  height: ${props => props.alto};
  width: ${props => props.ancho}
  /* height: auto;
  width: auto; */
  color: white;

  div.gatsby-image-wrapper {
    height: 100%;
    width: 100%;
  }

  div.leftArrow {
    display: flex;
    height: fit-content;
    align-items: center;
    z-index: 100;
    position: absolute;
    left: 0px;
    margin: 1rem;
    cursor: pointer;

       svg{
      height: 30px;
      width: 30px;
    }
    /* background-color: green; */
  }
  div.rightArrow {
    display: flex;
    height: fit-content;
    align-items: center;
    position: absolute;
    right: 0px;
    margin: 1rem;
    cursor: pointer;

    svg{
      height: 30px;
      width: 30px;
    }

    /* background-color: green; */
  }
`
